
import { defineComponent, computed, Ref, ref } from "vue";

export default defineComponent({
  props: {
    image: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const imageClass = computed(() => ({
      image: true,
      services: true,
      [props.image]: true,
    }));

    const visible: Ref<boolean> = ref(false);

    const modalClass = computed(() => ({
      modal: true,
      "preview-modal": true,
      visible: visible.value,
    }));

    function showModal() {
      visible.value = true;
    }

    function hideModal() {
      visible.value = false;
    }

    return {
      imageClass,
      modalClass,
      showModal,
      hideModal,
    };
  },
});
