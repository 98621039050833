
import { defineComponent } from "vue";

import VideoPlayer from "@/components/VideoPlayer.vue";
import TeamAvatar from "@/components/TeamAvatar.vue";
import ServiceImage from "@/components/ServiceImage.vue";
import PartnerImage from "@/components/PartnerImage.vue";

export default defineComponent({
  components: {
    ServiceImage,
    PartnerImage,
    VideoPlayer,
    TeamAvatar,
  },
});
