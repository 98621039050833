
import { defineComponent, ref, Ref } from "vue";

export default defineComponent({
  props: {
    src: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const basePath = process.env.BASE_URL;
    const resolvedPath: Ref<string> = ref(`${basePath}${props.src}`);
    const resolvedThubnail: Ref<string> = ref(`${basePath}${props.thumbnail}`);

    return {
      resolvedPath,
      resolvedThubnail,
    };
  },
});
