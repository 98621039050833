
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    image: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const imageClass = computed(() => ({
      image: true,
      partners: true,
      [props.image]: true,
    }));

    return {
      imageClass,
    };
  },
});
